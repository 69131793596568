import 'babel-polyfill';
import Headroom from 'headroom.js';
import { tns } from 'tiny-slider/src/tiny-slider';
import { CountUp } from 'countup.js';
import 'waypoints/lib/noframework.waypoints.min';
import 'cookieconsent/build/cookieconsent.min';
import smoothscroll from 'smoothscroll-polyfill';
import Plyr from 'plyr';

smoothscroll.polyfill();

const rootScope = {};

window.cookieconsent.initialise({
  container: document.getElementById('cookieconsent'),
  palette: {
    popup: { background: 'rgba(15, 28, 34, 0.8)' },
    button: { background: '#fff' }
  },
  revokable: false,
  onStatusChange: function (status) {
    console.log(this.hasConsented() ? 'enable cookies' : 'disable cookies');
  },
  position: 'bottom',
  theme: 'edgeless',
  domain: '/',
  secure: true,
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messageLink}}{{compliance}}'
  },
  elements: {
    messageLink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our terms of service" tabindex="0" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
      <span> and </span>
      <a aria-label="learn more about our privacy policy" tabindex="0" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> 
    </span>
    `
  },
  content: {
    header: 'Cookies used on the website!',
    message: 'The OneDash site uses cookies to provide you with a great user experience and for analytic purposes. By continuing to use our services, you are giving us your consent to use cookies in accordance with our ',
    dismiss: 'Accept and Continue',
    allow: 'Allow cookies',
    deny: 'Decline',
    close: '&#x274c;',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '/privacy-policy.html',
    tosLink: 'Terms of Use',
    tosHref: '/terms-of-use.html'
  }
});

const headerRef = document.querySelector('.base-header-js');

if (headerRef) {
  rootScope.headroom = new Headroom(headerRef);

  rootScope.headroom.init();
}

const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

const makeNavLinksSmooth = () => {
  const navLinks = document.querySelectorAll('.nav__link-js');

  for (const n in navLinks) {
    // eslint-disable-next-line no-prototype-builtins
    if (navLinks.hasOwnProperty(n)) {
      navLinks[n].addEventListener('click', e => {
        e.preventDefault();

        const element = document.querySelector(navLinks[n].hash);
        const elementPosition = getOffset(element).top;

        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        });

        setTimeout(() => {
          rootScope.headroom && rootScope.headroom.unpin();
        }, 2000);
      });
    }
  }
};

function scrollFunction (target) {
  if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
    target.style.display = 'block';
  } else {
    target.style.display = 'none';
  }
}

const scrollToTopBtnRef = document.querySelector('.scroll-to-js');

if (scrollToTopBtnRef) {
  scrollToTopBtnRef.addEventListener('click', (e) => {
    e.preventDefault();

    document.querySelector('.top-scroll-js').scrollIntoView({ behavior: 'smooth' });
  });
}

const spyScrolling = () => {
  const sections = document.querySelectorAll('.scrollspy');

  window.onscroll = () => {
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
    scrollFunction(scrollToTopBtnRef);

    for (const s in sections) {
      // eslint-disable-next-line no-prototype-builtins
      if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos) {
        const id = sections[s].id;
        document.querySelector('.active').classList.remove('active');
        document.querySelector(`a[href*=${id}]`).parentNode.classList.add('active');
      }
    }
  };
};

makeNavLinksSmooth();
spyScrolling();

const emailUrl = 'https://api.onedash.com/support/contact';

const popupTriggers = document.querySelectorAll('[data-popup-trigger]');

const removeParamsFromUrl = (paramName) => {
  const regPattern = new RegExp(`[\\?&]${paramName}=[^&]+`);

  window.history.replaceState && window.history.replaceState(
    null, '', window.location.pathname + window.location.search.replace(regPattern, '').replace(/^&/, '?') + window.location.hash
  );
};

// eslint-disable-next-line
window['toggleModal'] = (popupRef) => {
  const params = new URLSearchParams(window.location.search);
  const urlPopup = params.get('popup');

  if (popupRef.classList.contains('open') && urlPopup) {
    removeParamsFromUrl('popup');
  }

  popupRef.classList.contains('open')
    ? popupRef.classList.remove('open')
    : popupRef.classList.add('open');

  document.body.classList.contains('overflow-hidden')
    ? document.body.classList.remove('overflow-hidden')
    : document.body.classList.add('overflow-hidden');
};

if (popupTriggers && popupTriggers.length) {
  [...popupTriggers].map(item => {
    item.addEventListener('click', (e) => {
      const activeModalRef = document.querySelector('.modal.open');
      const popupRef = document.querySelector(`[data-popup=${e.target.dataset.popupTrigger}]`);

      if (activeModalRef || popupRef) {
        e.preventDefault();

        if (activeModalRef && popupRef !== activeModalRef) {
          activeModalRef.classList.remove('open');
          document.body.classList.remove('overflow-hidden');
          removeParamsFromUrl('popup');
        }

        window.toggleModal(popupRef);
      }
    });
  });
}

const urlParams = new URLSearchParams(window.location.search);
const popupTrigger = urlParams.get('popup');

if (popupTrigger) {
  const popupRef = document.querySelector(`[data-popup=${popupTrigger}]`);

  window.toggleModal(popupRef);
}

function validateEmail (email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const sendForm = async (url, body) => {
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  try {
    // eslint-disable-next-line no-undef
    const fetchResponse = await fetch(url, settings);
    return await fetchResponse.json();
  } catch (e) {
    return {
      error: true,
      message: e
    };
  }
};

const formHandler = async (e) => {
  e.preventDefault();

  const errors = new Map();
  const elements = e.target.elements;

  const [nameRef, emailRef, messageRef, submitBtnRef] = elements;

  const nameValue = nameRef.value;
  const emailValue = emailRef.value;
  const messageValue = messageRef.value;

  [...elements].map(el => {
    if (el.nextSibling) {
      el.nextSibling.innerHTML = '';
    }
  });

  if (nameValue.length < 2) {
    errors.set('name', 'Please enter at least 2 characters.');
  }

  if (!validateEmail(emailValue)) {
    errors.set('from', 'Email address is not valid.');
  }

  if (errors.size) {
    [...errors.keys()].map(key => {
      if (elements[key] && elements[key].nextSibling) {
        elements[key].nextSibling.innerHTML = errors.get(key);
      }
    });
    return false;
  }

  submitBtnRef.disabled = true;
  submitBtnRef.innerHTML = 'SENDING...';

  const response = await sendForm(emailUrl, {
    from: emailValue,
    name: nameValue,
    body: messageValue
  });

  submitBtnRef.disabled = false;
  submitBtnRef.innerHTML = 'CONTACT US';

  if (!response || response.status === 500) {
    // eslint-disable-next-line no-undef
    alert('Something went wrong!');
  } else {
    e.target.reset();
  }
};

const formRef = document.getElementById('contact-form');
const formModalRef = document.getElementById('contact-form-modal');

if (formRef) {
  formRef.addEventListener('submit', formHandler);
}
if (formModalRef) {
  formModalRef.addEventListener('submit', formHandler);
}

// eslint-disable-next-line no-unused-vars
const players = Plyr.setup('.js-plyr-player', {});

window.onload = () => {
  document.body.classList.remove('js-opaque');

  // const s = scrollSpy('#menu');
  // console.log(s);
};

const percentsWhyVideoSection = [87, 95, 37, 60, 58];
const percentCountDuration = 3;

const defineCounters = (refs, values) => {
  if (!refs.length) {
    return false;
  }

  return [...refs].map((item, i) => new CountUp(item, values[i], {
    suffix: '%',
    duration: percentCountDuration
  }));
};

const counters = defineCounters(
  document.querySelectorAll('.percents-box__value-js'), percentsWhyVideoSection
);

const setWidth = (refs, values) => {
  if (!refs.length) {
    return false;
  }

  return [...refs].map((item, i) => {
    item.style.transition = `width ${percentCountDuration}s ease`;
    item.style.width = `${values[i]}%`;
  });
};

const isMobileDevice = () => {
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4));
};

(() => {
  const percentsBoxRef = document.querySelector('.percents-box-js');

  if (percentsBoxRef) {
    // eslint-disable-next-line no-unused-vars
    const waypoint = new Waypoint({
      element: percentsBoxRef,
      handler: (direction) => {
        counters.map(item => item.start());
        setWidth(document.querySelectorAll('.percents-box__progress-completed-js'), percentsWhyVideoSection);
      },
      offset: '20%'
    });
  }

  if (isMobileDevice()) {
    const mobileHeaderToggleBtnRef = document.querySelector('.base-header__toggle-btn-js');
    const mobileNavRef = document.querySelector('.base-header__nav-mobile-js');

    const toggle = (el) => (event) => {
      event.stopPropagation();
      el.classList.contains('open') ? el.classList.remove('open') : el.classList.add('open');
    };

    mobileHeaderToggleBtnRef.addEventListener('click', toggle(mobileNavRef));

    document.body.addEventListener('click', (event) => {
      const isClickInside = mobileNavRef.contains(event.target);

      if (!isClickInside) {
        mobileNavRef.classList.remove('open');
      }
    });
  }

  // SLIDER
  // eslint-disable-next-line no-unused-vars
  const slider = tns({
    container: '.slider-js',
    items: 1,
    mode: 'gallery',
    slideBy: 'page',
    mouseDrag: true,
    controls: true,
    autoplay: true,
    navPosition: 'bottom',
    animateIn: 'slideIn',
    animateOut: 'slideOut',
    speed: 1000,
    loop: true
  });
})();

// QUESTIONS
const getHeight = (elem) => {
  elem.style.display = 'block';
  const height = elem.scrollHeight + 'px';
  elem.style.display = '';
  return height;
};

const show = (elem) => {
  const contentHeight = getHeight(elem);

  elem.classList.add('show');
  elem.style.height = contentHeight;

  const t = setTimeout(function () {
    elem.style.height = '';

    clearTimeout(t);
  }, 350);
};

const hide = (elem) => {
  elem.style.height = elem.scrollHeight + 'px';

  const t = setTimeout(function () {
    elem.style.height = '0';

    clearTimeout(t);
  }, 1);

  const t2 = setTimeout(function () {
    elem.classList.remove('show');

    clearTimeout(t2);
  }, 350);
};

const advantageBoxRef = document.querySelector('.advantage-accordion-js');
const advantagesCollectionRef = document.querySelectorAll('.advantage-accordion__item-js');

if (advantageBoxRef && advantagesCollectionRef.length) {
  advantagesCollectionRef[0].classList.add('active');
  show(advantagesCollectionRef[0].querySelector('.advantage-accordion__content-js'));

  advantageBoxRef.addEventListener('click', (event) => {
    const triggerRef = event.target.closest('.advantage-accordion__item-js');

    if (!triggerRef) return;

    if (!advantageBoxRef.contains(triggerRef)) return;

    const advantageContentRef = triggerRef.querySelector('.advantage-accordion__content-js');

    triggerRef.classList.add('active');
    show(advantageContentRef);

    [...advantagesCollectionRef]
      .filter(item => triggerRef !== item)
      .map(item => {
        item.classList.remove('active');
        const itemContentRef = item.querySelector('.advantage-accordion__content-js');
        hide(itemContentRef);
      });
  });
}
